import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import StickyBox from 'react-sticky-box'

import Carousel from 'src/components/shared/Carousel'
import { media } from 'src/styles/util'

import RightArrow128 from 'src/assets/img/icons/right-cursor-128.png'
import RightArrow32 from 'src/assets/img/icons/right-cursor-32.png'
import LeftArrow128 from 'src/assets/img/icons/left-cursor-128.png'
import LeftArrow32 from 'src/assets/img/icons/left-cursor-32.png'

import { IImageCarouselProps } from 'src/types/pdp'

const ImageWrapper = styled.div<{ largeSlider: boolean }>`
  position: relative;

  width: 100%;
  height: 100%;

  margin: 0 auto;

  display: grid;
  place-items: center;

  ${media.mobile`
    min-width: 93vw;
    padding-bottom: 10px;
  `}
  ${media.tablet`
    min-width: 330px;
  `}
  ${media.desktop`
    padding-bottom: 40px;
    ${(props: { largeSlider: boolean }) =>
      props.largeSlider
        ? `
      max-width: 700px;
      min-width: 0;
      height: auto;
      padding-bottom: 0;
      margin: 0 0 0 auto;
    `
        : `
      min-width: 400px;
     max-height: 600px;
    `};
  `}
`

const LeftClickBox = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: url(${LeftArrow128}) 128 100, url(${LeftArrow32}) 32 20, pointer;
`

const RightClickBox = styled(LeftClickBox)`
  left: auto;
  right: 0;
  cursor: url(${RightArrow128}) 128 100, url(${RightArrow32}) 32 20, pointer;
`

const Stick = styled(StickyBox)<{ largeSlider: boolean }>`
  width: 100%;
  height: fit-content;

  display: grid;

  ${media.mobile`

    .slick-dots {
      padding-right: 0;
      padding-bottom: 0;

      .slick-dots-bottom {
        bottom: 0;
      }
    }

    & .slick-list {
      width: 100%;
      padding: 0 2vw !important;
    }

    & .slick-slide {
      height: fit-content !important;

      & > div {
        height: 95% !important;
      }
    }
  `}
  ${media.tablet`
    & .slick-list {
      padding: 0 20px !important;
    }
    & .slick-slide {
      height: fit-content !important;
    }
  `}
  ${media.desktop`

    & .slick-slide {
      height: 500px !important;
    }

      width: 100%;
      .ant-carousel {
        height: 100% !important;
        overflow-x: hidden;

        .slick-dots {
          padding-left: 0px;
          padding-top: 80px;
        }
      }
      & .slick-list {
        width: 100% !important;
        left: 4.5%;
        padding-bottom: 50px !important;
      }

      & .slick-slide {
        height: 100% !important;
        & > div {
          height: 100% !important;
          display: flex;
        }
      }
  `}
`

const ImageCarousel: React.FC<IImageCarouselProps> = forwardRef(
  ({ carouselImages, largeSlider }, ref) => {
    const carouselRef = useRef(null)

    useImperativeHandle(ref, () => ({
      next: () => {
        if (carouselRef?.current) carouselRef.current.next()
      },
      prev: () => {
        if (carouselRef?.current) carouselRef.current.prev()
      },
      goTo: (index: number) => {
        if (carouselRef?.current) carouselRef.current.goTo(index)
      }
    }))

    const handleNext = () => {
      if (carouselRef?.current) carouselRef.current.next()
    }

    const handlePrev = () => {
      if (carouselRef?.current) carouselRef.current.prev()
    }

    return (
      <Stick offsetTop={120} offsetBottom={0} largeSlider={largeSlider}>
        <Carousel
          autoPlaySpeed={5000}
          autoPlay
          centerMode
          swipe
          effect="fade"
          slidesToShow={1}
          ref={carouselRef}
        >
          {carouselImages.map((image) => (
            <ImageWrapper key={image.alt} largeSlider={largeSlider}>
              <LeftClickBox onClick={handlePrev} />
              <RightClickBox onClick={handleNext} />
              <GatsbyImage
                image={getImage(image.src)}
                alt={image.alt}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: largeSlider ? 'cover' : 'contain' }}
              />
            </ImageWrapper>
          ))}
        </Carousel>
      </Stick>
    )
  }
)

export default ImageCarousel
