import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useReviews from 'src/hooks/useReviews'
import { reviewItems } from 'src/hooks/useReviews'

import AboveTheFold from './AboveTheFold'
import PressBanner from 'src/components/shared/PressBanner'
import Badges from './Badges'
import ProductDetail, { Swipe } from './ProductDetail'
import Dosage from '../shared/Dosage'
import VetEndorsement from 'src/components/shared/VetEndorsement'
import HowWereDifferent from './HowWereDifferent'
import Reviews from './Reviews'
import FAQ from './FAQ'
import ApprovedBy from './ApprovedBy'
import NascSeal from './NascSeal'
import WhatToExpect from './WhatToExpect'
import BuyOptions from './BuyOptions'
import Cards from './Cards'
import Guarantee from './Guarantee'
import { Singles, Bundles } from '../OurProducts'
import { colors } from 'src/styles/variables'
import { IPdpDataInterface } from 'src/types/pdp-template'
import Ingredients from './Ingredients'
import IngredientsPumpkin from './IngredientsPumpkin.tsx'
import Compare from './Compare'
import DogHero from './DogHero'
import PupCandle from './PupCandle'
import Quality from './Quality'
import IngredientsSkinHero from './IngredientsSkinHero'
import CompareSkin from './CompareSkin'

const Wrapper = styled.div`
  width: 100%;
`

export const usingNewPDP = [
  'allergyanditch',
  'allergy-and-itch',
  'hip-and-joint',
  'calming-aid',
  'vision-and-tear-stain',
  'digestive-probiotics',
  'multivitamin',
  'skin-and-coat',
  'chill-pup-bundle',
  'new-comfy-pup-bundle',
  'comfy-pup-bundle',
  'starter-bundle',
  'pretty-pup-bundle',
  'active-pup-bundle',
  'paw-hero',
  'skin-hero',
  'pumpkin-plus',
  'bone-broth-plus',
  'plant-based-poop-bags'
]

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

const PDP = ({ data, bundles }: { data: IPdpDataInterface }) => {
  const {
    slug,
    title,
    colors: { bg, color }
  } = data

  const [activeIndex, setActive] = useState(0)

  const setBundle = (idx) => setActive(idx)
  const key = getKeyByValue(reviewItems, title)
  const reviewsHook = useReviews(key)

  // temporary till all pages are done.
  if (usingNewPDP.includes(slug)) {
    if (slug === 'puppy-breath-candle') {
      return (
        <Wrapper>
          {/* <AboveTheFold reviewStats={reviewsHook.reviewStats} data={data} /> */}
          <PupCandle />
          <Bundles title="Shop Bundles" buttonCopy="Shop Now" />
          <Badges />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <AboveTheFold reviewStats={reviewsHook.reviewStats} data={data} />
        <PressBanner
          bg={
            slug === 'paw-hero' || slug === 'plant-based-poop-bags'
              ? 'lightBlue'
              : 'navy' && slug === 'pumpkin-plus'
              ? 'green'
              : 'navy' && slug === 'skin-hero'
              ? 'yellow'
              : 'navy'
          }
          color="navy"
          ticking
          homepage={slug === 'paw-hero' || slug === 'skin-hero' || slug === 'plant-based-poop-bags'}
        />
        {['paw-hero', 'skin-hero', 'plant-based-poop-bags'].includes(data.slug) ? (
          <Cards data={data} />
        ) : data.slug !== 'plant-based-poop-bags' ? (
          <ProductDetail
            data={data}
            bundles={bundles}
            setActiveBundle={setBundle}
            activeIndex={activeIndex}
          />
        ) : null}

        {slug === 'paw-hero' && <Ingredients data={data} />}
        {slug === 'paw-hero' && <Compare />}
        {/* {bundles.length > 0 && (
          <Swipe>
            {bundles.map(product =>
              <ApprovedBy data={product} key={product.title} bundles={bundles} setActiveBundle={setBundle} activeIndex={activeIndex} />
            )}
          </Swipe>
        )} */}
        {data.quality && <Quality quality={data.quality} />}
        {data?.buyOptions && slug === 'plant-based-poop-bags' && (
          <BuyOptions data={data} isBundle={data.isBundle} />
        )}
        {data.approvedBy &&
          !data?.isBundle &&
          ['allergyanditch', 'pumpkin-plus', 'bone-broth-plus', 'plant-based-poop-bags'].includes(
            data.slug
          ) && (
            <ApprovedBy
              data={data}
              bundles={bundles}
              setActiveBundle={setBundle}
              activeIndex={activeIndex}
            />
          )}
        {![
          'paw-hero',
          'pumpkin-plus',
          'skin-hero',
          'bone-broth-plus',
          'plant-based-poop-bags'
        ].includes(slug) && <VetEndorsement productReview={data.vetQuote} />}
        {bundles.length > 0 && (
          <Swipe>
            {bundles.map((product) => (
              <WhatToExpect
                data={product}
                bundles={bundles}
                setActiveBundle={setBundle}
                activeIndex={activeIndex}
              />
            ))}
          </Swipe>
        )}
        {/*data?.expectations && <WhatToExpect data={data} bundles={bundles} setActiveBundle={setBundle} activeIndex={activeIndex} />*/}
        {slug === 'skin-hero' && <CompareSkin />}
        {slug === 'pumpkin-plus' && <IngredientsPumpkin data={data} />}
        {slug === 'bone-broth-plus' && <IngredientsPumpkin data={data} />}
        {data?.buyOptions && slug !== 'plant-based-poop-bags' && (
          <BuyOptions data={data} isBundle={data.isBundle} />
        )}
        {slug === 'skin-hero' && <IngredientsSkinHero data={data} />}
        {slug !== 'paw-hero' && slug !== 'skin-hero' && slug !== 'plant-based-poop-bags' && (
          <NascSeal />
        )}
        {slug === 'paw-hero' && <DogHero />}
        <Reviews reviewsHook={reviewsHook} title={title} />
        <FAQ questionList={data.faqs} />
        {bundles.length > 0 && (
          <Swipe>
            {bundles.map((product) => (
              <Guarantee
                btnCopy={data?.isBundle ? data?.guarantee?.cta : product?.guarantee?.cta}
                copy={product}
                activeIndex={activeIndex}
              />
            ))}
          </Swipe>
        )}
        {!['paw-hero', 'skin-hero', 'plant-based-poop-bags'].includes(slug) &&
          data?.guarantee &&
          !data?.isBundle && <Guarantee copy={data} btnCopy={data?.guarantee?.cta} />}
        <Bundles title="Shop Bundles" buttonCopy="Shop Now" />
        <Badges />
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <AboveTheFold reviewStats={reviewsHook.reviewStats} data={data} />
        <Badges />
        <ProductDetail data={data} />
        <Dosage
          bg={colors[bg]}
          color={colors[color]}
          oneLiner="Our suggested daily intake for pups, from Yorkies to Great Danes and all the fuzzballs in between."
        />
        {!data.isBundle && <Bundles title="Save on Bundles" buttonCopy="Shop Now" />}
        <VetEndorsement productReview={data.vetQuote} />
        <HowWereDifferent slug={data.slug} />
        <Reviews reviewsHook={reviewsHook} />
        <FAQ questionList={data.faqs} />
        <Singles title="More Products" buttonCopy="Shop Now" isPdp />
      </Wrapper>
    )
  }
}

export default PDP
